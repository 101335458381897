export const reading_task_id = state => state.current_task_id;

export const filter_type_checkpoint = state => state.filter_type_checkpoint;

export const filter_date_range = state => state.filter_date_range;

export const ac_readings = state => state.ac_readings;

export const af_readings = state => state.af_readings;

export const checkpoints_readings = state => state.checkpoints_readings