export const getReadings = (context) => {
	// console.log('getReadings')
	const task_id = context.state.current_task_id
	const date_range = context.state.filter_date_range
	const checkpoint_filter = context.state.filter_type_checkpoint
	const query = {}
	if (task_id) {
		query.task_id = task_id
	}
	if (date_range.length > 0) {
		query.date_range = date_range
	}
	if (checkpoint_filter) {
		query.checkpoint_filter = checkpoint_filter
	}
	const params = window.$helpers.setUrlParameters(query)

	axios.get(`/api-admin/readings/getAllReadings/${params}`).then(response => {
		const {ac, af, checkpoints} = response
		// rows.map(item=>{
		// 	item.reading_date = moment(item.reading_date)
		// })
		context.commit('setReadings', {
			ac_readings: ac,
			af_readings: af,
			checkpoints_readings: checkpoints,
		})
	})
}