<template>
	<card-template>
		<div>
			<batch-tasks-table></batch-tasks-table>
			<ModalConfirmDeleteFn />
		</div>
	</card-template>
</template>

<script>
import BatchTasksTable from './BatchTasksTable.vue'
export default {
	name: 'TasksBatchList',
	components: {
		BatchTasksTable
	},
	created() {
		this.$store.commit('installations/hideInstallationsModal')
	}
}
</script>