<template>
	<card-template>
		<div class='row'>
			<div class='col-12 col-md-3 mb-2'>
				<label class='required'>Instalación</label>
				<input type='text' class='cursor-pointer form-control' :readonly=true v-model='item.installation_name' />
			</div>

			<div class='col-12 col-md-3 mb-2'>
				<label class='required'>Tipo de Tarea</label>
				<input type='text' class='form-control' :readonly=true value='Lote' />
			</div>

			<div class='col-12 col-md-3 mb-2'>
				<label class='required'>Nombre de la subtarea</label>
				<input type='text' class='form-control' v-model='item.name' />
			</div>

			<div class='col-12 col-md-3 mb-2'>
				<DatePickerComponent
					label='Período del lote'
					label-class='required'
					:current='[item.date,item.expiration_date]'
					@range='setDate'
				/>
			</div>

			<div class='col-12 col-md-3 mb-2'>
				<label class='required'>Operarios asignados</label>
				<UsersByInstallationCombo
					:installationId='item.installation_id'
					:multiple='true'
					:current='item.operarios_asign'
					@selected='setOperarios'
				/>
			</div>

			<div class='col-12 mb-3'>
				<label class='required'>Tipos de lectura a realiar</label>
				<ReadingTypeCombo :multiple='true' :setted='item.reading_keys' @selected='setReadings' />
			</div>

			<div class='col-12  mb-3'>
				<CheckpointSelectorComponent
					:checked-all='true'
					:installation='{id: item.installation_id, name:item.installation_name}'
					:current_checkpoints='item.checkpoints || []'
					@selected='setCheckpoints'
				/>
			</div>

			<div class='col-sm-12 mb-3 '>
				<label for='descriptionTask' class=''>{{ $tasks_locale.global.table_columns.description
					}}</label>
				<textarea id='descriptionTask' class='form-control'
						  v-model='item.description'></textarea>

			</div>

		</div>


		<div class='mb-3'>
			<TaskStateComponent
				:current_state_id='item.state_id'
				:current_state_type_id='item.state_type_id'
				:current_comments='item.comments'
				@setData='setStateData'
			/>
		</div>

		<button class='mt-3 btn btn-primary ' @click='save'>Guardar</button>
		<button class='mt-3 btn btn-danger ' @click='deleteBatch'>Eliminar</button>

		<ModalConfirmDeleteFn />
	</card-template>
</template>

<script>
import CheckpointSelectorComponent from '../checkpoints/CheckpointsSelectorComponent.vue'
import {mapGetters} from 'vuex'
import DatePickerComponent from '@/DatePickerComponent'
import UsersByInstallationCombo from '@/User/UsersByInstallationCombo'
import ReadingTypeCombo from '@modules/Readings/Resources/assets/components/ReadingTypeCombo.vue'
import TaskStateComponent from '../TaskStateComponent.vue'

export default {
	name: 'SubTaskBatchForm',
	props: {
		id: {required: true},
	},
	components: {
		CheckpointSelectorComponent,
		DatePickerComponent,
		UsersByInstallationCombo,
		ReadingTypeCombo,
		TaskStateComponent,
	},
	computed: {
		...mapGetters('system', ['app_config']),
		...mapGetters('tasks', ['current_batch_subtask']),
		targetLink() {
			return this.app_config.target_blank ? '_blank' : '_self'
		},
	},
	created() {
		this.$store.commit('tasks/setBatchSubtask',null)
		this.getSubtask()
	},
	watch: {
		current_batch_subtask(value) {
			this.item = {...value}
		},
	},
	data() {
		return {
			item: {},
		}
	},
	methods: {
		getSubtask() {
			this.$store.dispatch('tasks/getBatchSubtask', this.id)
		},
		async save() {
			const parent_id = this.item.batch_task_id
			await this.$store.dispatch('tasks/saveBatchSubtask', this.item)
			this.$helpers.toastSuccess()
			setTimeout(() => {
				window.open(`/tasks-batch/edit/${parent_id}`, this.targetLink)
			}, 1000)
		},
		deleteBatch() {
			this.$bus.$emit('fireModalConfirmDeleteFn', {
				text: `${this.$tasks_locale.global.modals.delete_task} ${this.item.name} `,
				parameters: null,
				fn: this.confirmDelete,
			})
		},
		async confirmDelete() {
			const parent_id = this.item.batch_task_id
			await this.$store.dispatch('tasks/deleteSubTask', {
				batch_id: parent_id,
				id: this.item.id,
			})
			this.$helpers.toastDeleteSuccess()
			setTimeout(() => {
				window.open(`/tasks-batch/edit/${parent_id}`, this.targetLink)
			}, 1000)
		},

		setDate(payload) {
			const [date, expiration_date] = payload.time
			this.item.date = date
			this.item.expiration_date = expiration_date
		},

		setOperarios(operarios) {
			const operators_values = operarios.map(item => item.id)
			this.item.operarios_asign = operators_values
		},
		setReadings(payload) {
			const reading_keys = payload.map(r => ({reading_key_id: r.id}))
			this.item.reading_keys = reading_keys
		},
		setCheckpoints(payload) {
			this.item.checkpoints = payload.checkpoints
		},
		setStateData(payload) {
			this.item.comments = payload.comments
			this.item.state_id = payload.state_id
			this.item.state_type_id = payload.state_type_id
		},
	},

}
</script>
