export const getSelectedTasks = (state) => {
	return state.selected_tasks
}

export const batch_tasks = (state) => {
	return state.batch_tasks
}

export const current_batch = (state) => {
	return state.current_batch
}

export const current_batch_subtask = (state) => {
	return state.current_batch_subtask
}