import { render, staticRenderFns } from "./ModalComponent.vue?vue&type=template&id=600670d0&scoped=true&"
import script from "./ModalComponent.vue?vue&type=script&lang=js&"
export * from "./ModalComponent.vue?vue&type=script&lang=js&"
import style0 from "./ModalComponent.vue?vue&type=style&index=0&id=600670d0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "600670d0",
  null
  
)

export default component.exports