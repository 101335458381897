<template>
    <card-template>
        <template slot="titleCard">{{ this.$tasks_locale.global.tasks_list }}</template>
        <div class="">
            <task-table
                :enableCreateBtn="true"
                :idTable="idTable"
                :states="states"
                :search="search"
                :columnsData="columns"
                :rowItems="items"
                :customFields="customFields"
                :customFilters="customFilters"
                :is_admin="is_admin"
                :btnConfig="false"
                @edit="editItem"
                @delete="deleteItem"
                @new="newItem"
                @config="setItem"
            >

            </task-table>
        </div>
        <modal-confirm-delete @confirmed="removeItem"/>

    </card-template>
</template>
<script>
import TaskTable from './TaskTable'
import {mapGetters} from 'vuex'
export default{
    name: 'TasksList',
    components: {TaskTable},
    props:['states','is_admin'],

    created() {
        this.getTasks()
    },
	computed:{
		...mapGetters('system',['app_config']),
		targetLink() {
			return this.app_config.target_blank ? '_blank' : '_self'
		},
	},

    data() {
        return {
            idTable: 'tasksTable',
            items: [],
            search: '',
            customFields: [

            ],
            customFilters:[

            ],
            columns: [
                {
                    field: 'id',
                    label: '#',
                    display: 'min_tabletP',
                },
                {
                    field: 'name',
                    label: this.$tasks_locale.global.table_columns.name,
                },
                {
                    field: 'type',
                    label: this.$tasks_locale.global.table_columns.type,
                },
                {
                    field: 'installation_name',
                    label: this.$tasks_locale.global.table_columns.installation,
                },
                {
                    field: 'items_count',
                    label: 'P. Control/Dep./Acc.'/*this.$tasks_locale.global.table_columns.customer*/,

                },
                // {
                //     field: 'zone',
                //     label: 'Zona'/*this.$tasks_locale.global.table_columns.options*/
                // },
                {
                    field: 'format_date',
                    label: this.$tasks_locale.global.table_columns.start_date
                },
                {
                    field: 'format_expiration_date',
                    label: this.$tasks_locale.global.table_columns.expiration_date
                },
                {
                    field: 'state',
                    label: this.$tasks_locale.global.table_columns.state
                },
                {
                    field: 'info',
                    label: this.$tasks_locale.global.table_columns.details
                },
                {
                    field: 'options',
                    label: this.$tasks_locale.global.table_columns.options,
                    // display:'min_desktop'

                }

            ]
        }
    },
    methods: {
        getTasks() {
            const filter = {task_status:'1'}
            const queryParams = this.$helpers.setUrlParameters(filter)
            axios.get(`/api-admin/tasks/getTasks/${queryParams}`).then(response => {
                const rows = response.data
                rows.map(item=>{
                    item.format_date = moment(item.format_date)
                    item.format_expiration_date = moment(item.format_expiration_date)
                })
                this.items = rows
            })
        },
        editItem(item) {
			const url = item.is_parent ? `/tasks-batch/edit/${item.id}` : `/tasks/${item.id}`
             window.open(url,this.targetLink)
        },
        deleteItem(item) {
            this.$bus.$emit('fireModalConfirmDelete', {
                text: `${this.$tasks_locale.global.modals.delete_task} ${item.name} `,
                parameters: item
            })
        },
        removeItem(item) {
            axios.delete(`/api-admin/tasks/${item.id}`).then(response => {
                const rows = response.data
                this.items = rows
                this.$toast.success(this.$t('toast.delete_success'))
                this.getTasks()
            })
        },
        newItem() {
            window.open(`/tasks/create`,this.targetLink)

        },
        setItem(item){
            window.open(`/tasks/${item.id}/config`,this.targetLink)
        },

    }

}
</script>
