<template>
	<div class="copyright text-center my-auto">
		<div class="flex items-center justify-center mt-4">
			<small><a href="https://m3e2.com/" target="_blank">
				Aplicación de M3E2 S.L.
			</a>
			</small>
			<img src="/images/logo_m3e2.jpg" width="40" alt="" class='mr-2'>
			<small class='text-primary'>v{{app_config.version}}</small>
		</div>

	</div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
	name: "Footer",
	computed:{
		...mapGetters('system',['app_config']),
	},
	created(){
		this.$store.dispatch('system/getConfig')
	},
}
</script>