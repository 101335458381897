<template>
	<v-client-table
		ref='BatchTasksTable'
		:columns='columns'
		:options='options'
		:data='tasks'
	>
		<div slot='options' slot-scope='{row}'>
			<button v-if="$helpers.hasPermission('tasks.edit')" @click='editItem(row)'
					class='btn btn-xs btn-warning'><i class='fa fa-pencil'></i></button>
			<button v-if="$helpers.hasPermission('tasks.delete')" @click='deleteItem(row)'
					class='btn btn-xs btn-danger'><i class='fa fa-trash'></i></button>

		</div>

		<template #date='{ row }'>
			{{ row.format_date }}
		</template>
		<template #expiration_date='{ row }'>
			{{ row.format_expiration_date }}
		</template>

		<template #info='{row}'>
			<div>{{ row.total_readings }} lecturas</div>
			<div>{{ row.percent_complete }}% tarea realizada</div>
		</template>
	</v-client-table>
</template>

<script>
import {mapGetters} from 'vuex'
import {datatables_options} from '@/../datatables_data'

export default {
	name: 'BatchTasksTable',
	props: {
		batch_tasks: {
			type: Array,
			default: () => ([]),
		},
		parent_task: {},
	},
	computed: {
		...mapGetters('system', ['app_config']),
		targetLink() {
			return this.app_config.target_blank ? '_blank' : '_self'
		},
		columnsData() {
			return [
				{
					field: 'id',
					label: '#',
					display: 'min_tabletP',
				},
				{
					field: 'name',
					label: this.$tasks_locale.global.table_columns.name,
				},

				{
					field: 'installation_name',
					label: this.$tasks_locale.global.table_columns.installation,
				},

				{
					field: 'date',
					label: this.$tasks_locale.global.table_columns.start_date,
				},
				{
					field: 'expiration_date',
					label: this.$tasks_locale.global.table_columns.expiration_date,
				},
				{
					field: 'state',
					label: this.$tasks_locale.global.table_columns.state,
				},
				{
					field: 'info',
					label: this.$tasks_locale.global.table_columns.details
				},
				{
					field: 'options',
					label: this.$tasks_locale.global.table_columns.options,
					// display:'min_desktop'

				},

			]
		},
	},
	created() {
		this.setColumns()
	},
	watch: {
		batch_tasks: function() {
			this.tasks = [...this.batch_tasks]
		},
	},
	data() {
		return {
			tasks: [],
			columns: [],
			options: {
				...datatables_options,
				filterByColumn: false,
			},
		}
	},
	methods: {
		setColumns() {
			const headings = {}
			this.columnsData.map(item => {
				this.columns.push(item.field)
				headings[item.field] = item.label
				if (item.display) {
					this.options.columnsDisplay[item.field] = item.display
				}
			})
			this.options.headings = headings
		},
		editItem(item) {
			window.open(`/tasks-batch/${this.parent_task.id}/subtask/${item.id}`, this.targetLink)
		},
		deleteItem(item) {
			this.$bus.$emit('fireModalConfirmDeleteFn', {
				text: `${this.$tasks_locale.global.modals.delete_task} ${item.name} `,
				parameters: item,
				fn: this.removeItem,
			})
		},
		async removeItem(item) {
			await this.$store.dispatch('tasks/deleteSubTask', {
				batch_id: this.parent_task.id,
				id: item.id
			})
			this.$toast.success(this.$t('toast.delete_success'))
		},
	},

}
</script>