<template>
	<card-template>
		<div class='row'>
			<div class='col-12 col-md-3 mb-2'>
				<label class='required'>Instalación</label>
				<input type='text' class='cursor-pointer form-control' @click='showInstalltionsSelector' :readonly=true v-model='installation.name' />
			</div>

			<div class='col-12 col-md-3 mb-2'>
				<label class='required'>Tipo de Tarea</label>
				<input type='text' class='form-control' :readonly=true value='Lote' />
			</div>

			<div class='col-12 col-md-3 mb-2'>
				<label class='required'>Nombre del Lote</label>
				<input type='text' class='form-control' v-model='batch.name' />
			</div>

			<div class='col-12 col-md-3 mb-2'>
				<DatePickerComponent
					label='Período del lote'
					label-class='required'
					:current='[batch.date,batch.expiration_date]'
					@range='setDate'
				/>
			</div>

			<div class='col-12 col-md-3 mb-2'>
				<label class='required'>Días de la semana de revisiones</label>
				<WeekDaysComponent @setDays='setDays' :current='batch.week_days' />
			</div>

			<div class='col-12 col-md-3 mb-2'>
				<label class='required'>Número de revisiones por día</label>
				<input type='number' class='form-control' v-model='batch.checkpoints_by_day' />
			</div>

			<div class='col-12 col-md-3 mb-2'>
				<label class='required'>Operarios asignados</label>
				<UsersByInstallationCombo
					:installationId='installation.id'
					:multiple='true'
					:current='batch.operarios_asign'
					@selected='setOperarios'
				/>
			</div>

			<div class='col-12 mb-3'>
				<label class='required'>Tipos de lectura a realiar</label>
				<ReadingTypeCombo :multiple='true' :setted='batch.reading_keys' @selected='setReadings' />
			</div>

			<div class='col-12  mb-3'>
				<CheckpointSelectorComponent
					:checked-all="true"
					:installation='installation'
					:current_checkpoints='batch.checkpoints || []'
					@selected='setCheckpoints'
				/>
			</div>


			<div class='col-sm-12 mb-3 '>
				<label for='descriptionTask' class=''>{{ $tasks_locale.global.table_columns.description
					}}</label>
				<textarea id='descriptionTask' class='form-control'
						  v-model='batch.description'></textarea>

			</div>

		</div>
		<button v-show="!id" class='mt-3 btn btn-primary ' @click='save'>Guardar</button>
		<button v-show="id" class='mt-3 btn btn-danger ' @click='deleteBatch'>Eliminar</button>
		<hr class='my-3'>
		<div>
			<h4>Subtareas</h4>
			<BatchSubTasksTable :parent_task='batch' :batch_tasks='batch.batch' />
		</div>

		<InstallationsModal v-if='!id' @setInstallation='setInstallation' />
		<ModalConfirmDeleteFn />
	</card-template>
</template>

<script>
import CheckpointSelectorComponent from '../checkpoints/CheckpointsSelectorComponent.vue'
import {mapGetters} from 'vuex'
import InstallationsModal from '@modules/Installations/Resources/assets/components/InstallationsModal.vue'
import DatePickerComponent from '@/DatePickerComponent'
import WeekDaysComponent from '../WeekDaysComponent.vue'
import UsersByInstallationCombo from '@/User/UsersByInstallationCombo'
import ReadingTypeCombo from '@modules/Readings/Resources/assets/components/ReadingTypeCombo.vue'
import BatchSubTasksTable from './BatchSubTasksTable.vue'
export default {
	name: 'TasksBatchForm',
	props: ['id'],
	components: {
		CheckpointSelectorComponent,
		InstallationsModal,
		DatePickerComponent,
		WeekDaysComponent,
		UsersByInstallationCombo,
		ReadingTypeCombo,
		BatchSubTasksTable
	},
	computed: {
		...mapGetters('tasks', ['current_batch']),
		...mapGetters('system', ['app_config']),
		targetLink() {
			return this.app_config.target_blank ? '_blank' : '_self'
		},
	},
	created() {
		if (this.id) {
			this.$store.dispatch('tasks/getBatchTask', this.id)
		} else {
			this.resetBatch()
		}
	},
	mounted() {
		this.$store.commit('installations/hideInstallationsModal')
		if (!this.id) {
			$(() => {
				this.showInstalltionsSelector()
			})
		}
	},
	watch: {
		current_batch(value) {
			if (value) {
				this.batch = {...value}
				this.installation = {
					id: value.installation_id,
					name: value.installation_name,
				}
			} else {
				this.resetBatch()
			}
		},
	},
	data() {
		return {
			installation: {},
			batch: {},
		}
	},
	methods: {
		showInstalltionsSelector() {
			this.$store.commit('installations/toggleInstallationsModal')
		},
		resetBatch() {

			this.batch = {
				id: null,
				type_id: null,
				is_parent: true,
				state_id: null,
				state_type_id: null,
				name: '',
				date: '',
				expiration_date: '',
				operarios_asign: '',
				installation_id: null,
				installation_name: null,
				description: '',
				comments: '',
				week_days: [],
				checkpoints_by_day: 0,
				checkpoints: [],
				reading_keys: [],
				batch: [],
			}
		},
		async save() {
			this.$store.commit('tasks/setCurrentBatch', this.batch)
			await this.$store.dispatch('tasks/saveBatchTask')
			window.open('/tasks-batch', this.targetLink)
		},
		deleteBatch(){
			this.$bus.$emit('fireModalConfirmDeleteFn', {
				text: `${this.$tasks_locale.global.modals.delete_task} ${this.batch.name} `,
				parameters: null,
				fn: this.confirmDelete
			})
		},
		async confirmDelete(){
			await this.$store.dispatch('tasks/deleteBatchTask', this.batch.id)
			this.$helpers.toastDeleteSuccess()
			setTimeout(() => {
				window.open('/tasks-batch', this.targetLink)
			},1000)
		},
		setInstallation(installation) {
			this.batch.installation_id = installation.id
			this.batch.installation_name = installation.name
			this.installation = installation
		},
		setDate(payload) {
			const [date, expiration_date] = payload.time
			this.batch.date = date
			this.batch.expiration_date = expiration_date
		},
		setDays(days) {
			this.batch.week_days = days.map(day => day.value)
		},
		setOperarios(operarios) {
			const operators_values = operarios.map(item => item.id)
			this.batch.operarios_asign = operators_values
		},
		setReadings(payload) {
			const reading_keys = payload.map(r => ({reading_key_id: r.id}))
			this.batch.reading_keys = reading_keys
		},
		setCheckpoints(payload){
			this.batch.checkpoints = payload.checkpoints
		}
	},

}
</script>
