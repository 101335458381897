<template>
	<div :id='id' class='modal fade' tabindex='-1' role='dialog'>
		<div
			:class="[
				'modal-dialog',
				modalSize
			]"
			role='document'
		>
			<div class='modal-content'>
				<div class='modal-header'>
					<slot name='modalHeader'></slot>
					<h4 class='modal-title'>
						<slot name='modalTitle'></slot>
					</h4>
					<button
						type='button'
						class='close'
						data-dismiss='modal'
						aria-label='Close'
						@click='closeModal'
					>
						<span aria-hidden='true'>&times;</span>
					</button>
				</div>
				<div :class='`modal-body ${padding} m-0b`'>
					<slot></slot>
				</div>
				<div class='modal-footer'>
					<div v-if='!hideButtons' class='d-flex justify-items-center'>
						<button
							type='button'
							class='btn btn-primary btn-sm mr-2'
							@click='confirmModal'
						>
							{{ $t('buttons.submit') }}
						</button>
						<button
							type='button'
							class='btn btn-danger btn-sm'
							data-dismiss='modal'
							@click='closeModal'
						>
							{{ $t('buttons.cancel') }}
						</button>
					</div>
					<slot name='modalFooter'></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		id: {required: true, type: String},
		show: {type: Boolean},
		modalSize: {required: false, default: 'modal-lg'},
		hideButtons: {default: false},
		padding: {default: 'p-2'},
	},
	watch: {
		show: function(value) {
			if (value) {
				this.showModal()
			} else {
				this.closeModal()
			}
		},
	},
	methods: {
		showModal() {
			$(`#${this.id}`).modal('show')
			this.$emit('open-modal')
		},
		closeModal() {
			$(`#${this.id}`).modal('hide')
			this.$emit('closeModal')
		},
		confirmModal() {
			this.$emit('Submitted')
		},
	},
}
</script>

<style lang='scss' scoped>
.modal-body {
	min-height: 200px;
	max-height: 800px;
	overflow-y: auto;
}

.modal-fullscreen {
	width: 80vw;
	height: 80vh;
	margin-top: 10vh;
	margin-left: 10vw;
	max-width: 100%;
}

.modal-fullscreen .modal-content {
	height: 100%;
}
</style>
