<template>
   <ReadingsTabs />
</template>
<script>
import ReadingsTabs from './ReadingsTabs.vue'
export default{
    name: 'ReadingList',
	components:{
		ReadingsTabs
	},
	created(){
		this.$store.commit('readings/resetData')
		this.$store.dispatch('readings/getReadings')
	}
}
</script>
