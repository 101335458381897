<template>
    <card-template>
        <template slot="titleCard">{{ this.$readings_locale.global.readings_list }}</template>
        <div class="">
            <reading-table
                :user_admin="user_admin"
                :type="'af'"
                :enableCreateBtn="false"
                :idTable="idTable"
                :columnsData="columns"
                :customFilters="customFilters"
                :rowItems="af_readings"
                :customFields="customFields"
                :btnConfig="false"
                @edit="editItem"
                @delete="deleteItem"
            ></reading-table>
        </div>
        <modal-confirm-delete @confirmed="removeItemDeposit"/>

    </card-template>
</template>
<script>
import{mapGetters} from 'vuex'

export default{
    name: 'ReadingDepositList',
    props:['readings_ids','user_admin'],
    mounted() {

    },
    data() {
        return {
            idTable: 'readingsTable',
            items: [],
            customFilters: [
                'date_range'
            ],
            customFields: [

            ],
            columns:[
                {
                    "field": "id",
                    "label": "#",
                    "display": "min_tabletP"
                },
                {
                    "field": "reading_date",
                    "label": this.$readings_locale.global.table_columns.reading_date
                },
                {
                    "field": "installation_name",
                    "label": this.$readings_locale.global.table_columns.installation
                },{
                    "field": "item_name",
                    "label": this.$readings_locale.global.table_columns.deposit
                },
                {
                    "field": "options",
                    "label": this.$readings_locale.global.table_columns.detail
                }
            ]
        }
    },
    computed: {
		...mapGetters('readings', ['af_readings']),
	},
    methods: {
        getReadingsDeposit() {
			this.$store.dispatch('readings/getReadings')
        },
        editItem(item) {
            window.location = `/readings/${item.id}`
        },
        deleteItem(item) {
            this.$swal.fire({
                title: this.$t('modals.confirm_delete.title'),
                html:  ` `,
                icon: 'warning',
                showCancelButton: true,
                // confirmButtonColor: translate('js_messages.buttons.bg'),
                // cancelButtonColor: translate('js_messages.confirm.cancelBtnColor'),
                confirmButtonText: this.$t('buttons.submit'),
                cancelButtonText: this.$t('buttons.cancel')
            }).then((result) => {
                if (result.isConfirmed) {
                    this.removeItemDeposit(item)
                }
            })
        },
        removeItemDeposit(item) {
            axios.delete(`/api-admin/readings/${item.id}`).then(response => {
                this.$toast.success(this.$t('toast.delete_success'))
                this.getReadingsDeposit()
            })
        },
        newItem() {
            window.location = '/readings/create'
        },
        setItem(item){
            window.location = `/readings/${item.id}/config`
        }
    }

}
</script>

<style lang="scss">
.VueTables__table{
    td{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        max-width: 300px;
        font-size: 14px;

    }
    //td:hover{
    //    max-width: 300px;
    //    font-size: 14px;
    //    white-space: normal;
    //    overflow: visible;
    //}

}
.table-responsive:not(.VueTables .table-responsive){
    overflow: hidden;
    height: 100vh;
}
</style>
