<template>
	<div>
		<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
			<li class="nav-item">
				<a class="nav-link active" id="pills-task-tab" data-toggle="pill" href="#tabTask" role="tab"
				   aria-controls="pills-ac" aria-selected="true">
					Tarea
				</a>
			</li>
			<li class="nav-item">
				<a class="nav-link " id="pills-checkp-tab" data-toggle="pill" href="#tabReadings" role="tab"
				   aria-controls="pills-af" aria-selected="true">
					Lecturas
				</a>
			</li>
		</ul>
		<div class="tab-content" id="pills-tabContent">
			<div class="tab-pane show fade active" role="tabpanel" id="tabTask" >
				<BatchSubTaskForm :id='task_id' />
			</div>
			<div class="tab-pane fade" role="tabpanel" id="tabReadings">
				<card-template :show_header="false">
					<ReadingsTabs active_tab='checkpoints' :show_ac='false' :show_af='false' :task_id="task_id" />
				</card-template>
			</div>
		</div>
	</div>

</template>
<script>
import ReadingsTabs from '@modules/Readings/Resources/assets/components/ReadingsTabs.vue'
import BatchSubTaskForm from './BatchSubTaskForm.vue'
export default {
	name: 'SubTaskComponent',
	props:{
		task_id:{}
	},
	components:{
		BatchSubTaskForm,
		ReadingsTabs
	},
	created(){

	}
}
</script>