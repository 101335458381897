<template>
	<v-client-table
		ref='BatchTasksTable'
		:columns='columns'
		:options='options'
		:data='batch_tasks'
	>
		<div slot='beforeTable'>
			<div class='text-right'>
				<button v-if="this.$helpers.hasPermission('tasks.edit')" @click='newItem'
						class='btn btn-primary btn-sm mb-2'><i class='fa fa-plus'></i>
					{{ this.$tasks_locale.global.buttons.new }}
				</button>
			</div>
		</div>

		<div slot='options' slot-scope='{row}'>
			<button v-if="$helpers.hasPermission('tasks.edit')" @click='editItem(row)'
					class='btn btn-xs btn-warning'><i class='fa fa-pencil'></i></button>
			<button v-if="$helpers.hasPermission('tasks.delete')" @click='deleteItem(row)'
					class='btn btn-xs btn-danger'><i class='fa fa-trash'></i></button>

		</div>

		<template #date='{ row }'>
			{{ row.format_date }}
		</template>
		<template #expiration_date='{ row }'>
			{{ row.format_expiration_date }}
		</template>
	</v-client-table>
</template>

<script>
import {mapGetters} from 'vuex'
import {datatables_options} from '@/../datatables_data'

export default {
	name: 'BatchTasksTable',
	computed: {
		...mapGetters('tasks', ['batch_tasks']),
		...mapGetters('system', ['app_config']),
		targetLink() {
			return this.app_config.target_blank ? '_blank' : '_self'
		},
		columnsData() {
			return [
				{
					field: 'id',
					label: '#',
					display: 'min_tabletP',
				},
				{
					field: 'name',
					label: this.$tasks_locale.global.table_columns.name,
				},

				{
					field: 'installation_name',
					label: this.$tasks_locale.global.table_columns.installation,
				},

				{
					field: 'date',
					label: this.$tasks_locale.global.table_columns.start_date,
				},
				{
					field: 'expiration_date',
					label: this.$tasks_locale.global.table_columns.expiration_date,
				},
				{
					field: 'state',
					label: this.$tasks_locale.global.table_columns.state,
				},
				{
					field: 'subtasks',
					label: 'Subtareas'
				},
				{
					field: 'options',
					label: this.$tasks_locale.global.table_columns.options,
					// display:'min_desktop'

				},

			]
		},
	},
	created() {
		this.setColumns()
		this.getData()
	},
	data() {
		return {
			columns: [],
			options: {
				...datatables_options,
				filterByColumn: false,
			},
		}
	},
	methods: {
		setColumns() {
			const headings = {}
			this.columnsData.map(item => {
				this.columns.push(item.field)
				headings[item.field] = item.label
				if (item.display) {
					this.options.columnsDisplay[item.field] = item.display
				}
			})
			this.options.headings = headings
		},
		getData() {
			this.$store.dispatch('tasks/getBatchTasksList')
		},
		newItem() {
			window.open(`/tasks-batch/new`, this.targetLink)
		},
		editItem(item) {
			window.open(`/tasks-batch/edit/${item.id}`, this.targetLink)
		},
		deleteItem(item) {
			this.$bus.$emit('fireModalConfirmDeleteFn', {
				text: `${this.$tasks_locale.global.modals.delete_task} ${item.name} `,
				parameters: item,
				fn: this.removeItem
			})
		},
		async removeItem(item) {
			await this.$store.dispatch('tasks/deleteBatchTask', item.id)
			this.$helpers.toastDeleteSuccess()
		},
	},

}
</script>