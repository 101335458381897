export const setSelectedTasks = (state,payload)=> {
	state.selected_tasks = payload
}

export const setBatchTasks = (state,payload)=> {
	state.batch_tasks = payload
}

export const setCurrentBatch = (state,payload)=> {
	state.current_batch = payload
}

export const setBatchSubtask = (state,payload)=> {
	state.current_batch_subtask = payload
}