<template>
	<div class='row'>
		<span class='w-100 mb-2'>{{ $tasks_locale.global.change_state }} </span>
		<div class='col-12 col-sm-3 mb-2 '>
			<div class='w-100'>
				<label for='taskState' class=' w-100 required'>{{
						$tasks_locale.global.table_columns.state }} </label>
				<TaskStateCombo :current_id='state_id' @selected='setState' />
			</div>
		</div>
		<div class='col-12 col-sm-3 mb-2 '>
			<div class='w-100'>
				<label for='taskStateType' class=' w-100 required'>{{
						$tasks_locale.global.table_columns.state_type }} </label>
				<TaskStateTypesCombo :current_id='state_type_id' @selected='setStateType' />
			</div>
		</div>
		<div class='col-12 mb-2 '>
			<label for='commentsTask' class=''>{{ $tasks_locale.global.table_columns.comments
				}}</label>
			<textarea id='commentsTask' class='form-control'
					  v-model='comments' @change='emitData'></textarea>
		</div>
	</div>
</template>
<script>
import TaskStateTypesCombo from './TaskStateTypesCombo.vue'
import TaskStateCombo from './TaskStatesCombo.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import TaskState from './TaskStateComponent'

export default {
	name: 'TaskStateComponent',
	props: {
		current_state_id: {type: Number},
		current_state_type_id: {type: Number},
		current_comments: {type: String},
	},
	components: {
		TaskStateTypesCombo,
		TaskStateCombo,
		vSelect,
	},
	watch: {
		current_comments: function() {
			this.comments = this.current_comments
		},
		current_state_id: function(){
			this.state_id = this.current_state_id
		},
		current_state_type_id: function(){
			this.state_type_id = this.current_state_type_id
		}
	},
	created(){
		this.state = null,
		this.state_type= null,
		this.comments = null
	},
	data() {
		return {
			state_id: null,
			state_type_id: null,
			comments: null,
		}
	},
	methods: {
		setState(state) {
			this.state_id = state?.id
			this.emitData()
		},
		setStateType(type) {
			this.state_type_id = type?.id
			this.emitData()
		},
		emitData() {
			this.$emit('setData', {
				state_id: this.state_id,
				state_type_id: this.state_type_id,
				comments: this.comments,
			})
		},
	},
}
</script>