<template>
	<div class='btn-group btn-group-toggle' data-toggle='buttons'>
		<label for='selectAll' class='btn btn-xs cursor-pointer active'>
			<input  @click='emitAll' type='radio' name='options' id='selectAll' autocomplete='off'>
			<i class='fa fa-square-check'></i> Seleccionar Todo
		</label>
		<label for='unselectAll' class='btn btn-xs cursor-pointer'>
			<input @click='emitEmpty'  type='radio' name='options' id='unselectAll' autocomplete='off'>
			<i class='fa fa-square'></i> Deseleccionar Todo
		</label>
	</div>
</template>
<script>
export default {
	name: 'BulkSelectionComponent',
	methods: {
		emitAll() {
			this.$emit('selectAll')
		},
		emitEmpty() {
			this.$emit('unselectAll')
		}
	}
}

</script>