<template>
    <card-template>
        <template slot="titleCard">{{ this.$readings_locale.global.readings_list }}</template>
        <div class="">
            <reading-table
                :user_admin="user_admin"
                :type="'checkpoints'"
                :enableCreateBtn="false"
                :idTable="idTable"
                :columnsData="columns"
                :customFilters="customFilters"
                :rowItems="checkpoints_readings"
                :btnConfig="false"
                @edit="editItem"
                @delete="deleteItem"
            ></reading-table>

        </div>
        <modal-confirm-delete @confirmed="removeItemCheckpoint"/>
    </card-template>
</template>
<script>
import ReadingTable from './ReadingTable'
import {mapGetters} from 'vuex'
export default {
    name: 'ReadingCheckpointList',
    props:['readings_ids','user_admin'],
	components:{
		ReadingTable
	},
	created(){

	},
    mounted() {

    },
	computed: {
		...mapGetters('readings', ['checkpoints_readings']),
	},
    data() {
        return {
            idTable: 'readingsTable',
            customFilters: [
                'checkpoints',
                'date_range'
            ],
            columns: [
                {
                    "field": "id",
                    "label": "#",
                    "display": "min_tabletP"
                },

                {
                    "field": "reading_date",
                    "label": this.$readings_locale.global.table_columns.reading_date
                },
                {
                    "field": "installation_name",
                    "label": this.$readings_locale.global.table_columns.installation
                },
                {
                    "field": "zone_path",
                    "label": this.$readings_locale.global.table_columns.ubication
                },
                {
                    "field": "item_name",
                    "label": this.$readings_locale.global.table_columns.checkpoint
                },
                {
                    "field": "options",
                    "label": this.$readings_locale.global.table_columns.detail
                }
            ]
        }
    },
    methods: {
        getReadingsCheckpoints() {
            this.$store.dispatch('readings/getReadings')
        },
        editItem(item) {
            window.location = `/readings/${item.id}`
        },
        deleteItem(item) {
            this.$swal.fire({
                title: this.$t('modals.confirm_delete.title'),
                html:  ` `,
                icon: 'warning',
                showCancelButton: true,
                // confirmButtonColor: translate('js_messages.buttons.bg'),
                // cancelButtonColor: translate('js_messages.confirm.cancelBtnColor'),
                confirmButtonText: this.$t('buttons.submit'),
                cancelButtonText: this.$t('buttons.cancel')
            }).then((result) => {
                if (result.isConfirmed) {
                    this.removeItemCheckpoint(item)
                }
            })
        },
        removeItemCheckpoint(item) {
            axios.delete(`/api-admin/readings/${item.id}`).then(response => {
                this.$toast.success(this.$t('toast.delete_success'))
                this.getReadingsCheckpoints()
            })
        },
        newItem() {
            window.location = '/readings/create'
        },
        setItem(item) {
            window.location = `/readings/${item.id}/config`
        }
    }
}
</script>
