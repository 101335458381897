<template>
	<v-select :options="states" v-model="selected" label="label" code="id" @input="emitSelected"></v-select>
</template>
<script>
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css"
export default {
	name: 'TaskStateTypesCombo',
	props:{
		current_id: {}
	},
	components:{
		vSelect
	},
	created(){
		this.getData()
	},
	watch:{
		current_id(){
			this.setCurrent()
		}
	},
	data(){
		return {
			states:[],
			selected: null
		}
	},
	methods:{
		getData(){
			axios.get('/api-admin/tasks/task-state-types',{hideLoading:true}).then(response=>{
				this.states=response
				this.setCurrent()
			})
		},
		setCurrent(){
			if(this.current_id){
				this.selected = this.states.find(state => state.id == this.current_id)
				// this.emitSelected()
			}
		},
		emitSelected(){
			this.$emit('selected',this.selected)
		}
	}
}
</script>