<template>
	<div>
		<modal :show='show' @close='close' @acept='countCpSelected'>
			<div class='base col-lg-12 col-12 mb-2'>
				<div class='row'>
					<div class='col-12 col-sm-8 text-center'>
						<span class='h4 font-weight-bold'>{{ $tasks_locale.global.select_checkpoints_title }}</span>
					</div>
					<div class='col-12 col-sm-4 text-right'>
						<BulkSelectionComponent @selectAll='selectAll' @unselectAll='unselectAll' />
					</div>
				</div>

				<VueTree
					ref='tree1'
					showCheckbox
					expandedAll
					:treeData='this.treeData'
					@on-checked-item='checkedItem'
					@on-selected-change='clickNode'
					@on-checked-change='clickCheckbox'
				/>
			</div>
		</modal>
	</div>
</template>

<script>
import Modal from '../Modal.vue'
import VueTree from '../../js/light-vue-tree'
import '../../css/vue-tree.css'
import BulkSelectionComponent from '@/BulkSelectionComponent.vue'

export default {
	name: 'CheckpointTreeModal',
	components: {
		Modal,
		'VueTree': VueTree,
		BulkSelectionComponent,
	},
	data() {
		return {
			treeData: [],
			countCp: 0,
			formData: {
				checkpoints: [],
			},
		}
	},
	props: {
		'show': {type: Boolean, default: false},
		'checkpoints': {type: Array, default: () => ([])},
		'deposits': {type: Array, default: () => ([])},
		'accumulators': {type: Array, default: () => ([])},
		'installation_id': {type: Number},
		'checkedAll': {type: Boolean, default: false},
		'getDeposits': {type: Boolean, default: true},
		'getAccumulators': {type: Boolean, default: true},
		'getCheckpoints': {type: Boolean, default: true},
	},
	async mounted() {
		if (this.getCheckpoints) {
			await this.setZonesCheckboxTree()
		}
		if (this.getDeposits || this.getAccumulators) {
			await this.setAccumulatorCheckboxTree()
		}
		this.countCpSelected()
	},
	created() {

	},
	watch: {},
	methods: {
		close: function() {
			$('body').css('overflow', 'visible')
			this.$emit('close')
		},
		countCpSelected: function() {
			$('.icon-unexpand').click()
			setTimeout(() => {
				let cpSelected = []
				let accSelected = []
				let depSelected = []
				$('.vue-tree input:checked').each((k, v) => {
					let node = $(v).closest('.vue-checkbox')
					let nodeDataId = node.attr('data-id')
					if (node.attr('data-type') == 'accum') {
						nodeDataId != 0 ? accSelected.push(nodeDataId) : ''
					} else if (node.attr('data-type') == 'deposit') {
						nodeDataId != 0 ? depSelected.push(nodeDataId) : ''
					} else {
						nodeDataId != 0 ? cpSelected.push(nodeDataId) : ''
					}
				})
				$('#checkpointsSelected').val(cpSelected)
				$('#accumSelected').val(accSelected)
				$('#depSelected').val(depSelected)
				this.countCp = cpSelected.length + accSelected.length + depSelected.length
				$('#count-cp').text(this.countCp)
				$('.info-cp-selected').removeClass('d-none')
				this.$emit('selected', {
					checkpoints: cpSelected,
					accumulators: accSelected,
					deposits: depSelected,
				})
			}, 200)
		},

		checkedItem({node, vNode}) {

		},
		clickNode(node) {

		},
		clickCheckbox({node, selectedData}) {

		},
		setZonesCheckboxTree() {
			return axios.get(`/api-admin/installations/${this.installation_id}/zones/treeVue`)
			.then((res) => {
				res.forEach((v) => {
					if (this.checkedAll && this.checkpoints.length === 0) {
						v['checked'] = true
					}
					this.treeData.push(v)
				})

				this.checkpoints.forEach((v) => {
					this.treeData.forEach((t) => {
						this.searchTree(t, v)
					})
				})
			})
			.catch((err) => {
				console.error('error setZonesCheckboxTree', err)
			})
		},
		setAccumulatorCheckboxTree() {
			return axios.get(`/api-admin/installations/${this.installation_id}/circuits/treeVue`)
			.then((res) => {
				res.forEach((v) => {
					this.treeData.push(v)
				})
				//this.setZonesCheckboxTree();
				this.treeData.forEach((t) => {
					this.accumulators.forEach((v) => {
						this.searchTree(t, v)
					})
					this.deposits.forEach((v) => {
						this.searchTree(t, v)
					})
				})
			})
			.catch((err) => {
				console.error('error de setAccumulatorCheckboxTree', err)
			})
		},
		searchTree(element, matchingTitle) {
			if (element.id == matchingTitle) {
				element['checked'] = true
				return element
			} else if (element.children != null) {
				var i
				var result = null
				for (i = 0; result == null && i < element.children.length; i++) {
					result = this.searchTree(element.children[i], matchingTitle)
				}
				return result
			}
			return null
		},
		selectAll() {
			this.treeData = this.treeData.map(item => {
				return this.toggleBulkSelection(item, true)
			})
		},
		unselectAll() {

			this.treeData = this.treeData.map(item => {
				return this.toggleBulkSelection(item, false)
			})
		},

		toggleBulkSelection(element, checked) {
			element['checked'] = checked
			if (element.children != null) {
				for (let i = 0;  i < element.children.length; i++) {
					 this.toggleBulkSelection(element.children[i], checked)
				}
				return element
			}else{
				return element
			}
		},
	},
}
</script>

<style lang='scss'>
.vue-checkbox-input {
	width: 15px;
	height: 15px;
}
</style>
