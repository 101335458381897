<template>
	<div>
		<label :class="labelClass">{{ titleField }}</label>
		<v-datepicker
			v-model="time"
			:format="format_time_view"
			:range="range"
			:disabled="disabled"
			:type="format_type"
			@change="emitTime"
		/>
	</div>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/es'

export default {
	components: {
		'v-datepicker': DatePicker
	},
	props: {
		range: { default: true },
		label: {
			required: false
		},
		disabled: {
			default: false
		},
		current: {
			required: false
		},
		format_type: {
			default: 'date'
		},
		labelClass: {
			type: String,
			default: ''
		}
	},
	created () {
		this.setCurrent()
	},

	computed: {
		format_time_view () {
			if (this.format_type === 'datetime') {
				return 'DD/MM/YYYY HH:mm'
			}

			return 'DD/MM/YYYY'
		},
		format_time_store () {
			if (this.format_type === 'datetime') {
				return 'YYYY-MM-DD HH:mm:ss'
			}

			return 'YYYY-MM-DD'
		},
		locale: function () {
			return this.$trans.resources
		},
		titleField () {
			if (this.label) {
				return this.label
			}
			return this.range ? this.$t('select_range_label') : this.$t('Date')
		}
	},
	watch: {
		current () {
			this.setCurrent()
		}
	},
	data () {
		return {
			time: null,
			lang: {
				formatLocale: {
					firstDayOfWeek: 1
				},
				monthBeforeYear: false
			}
		}
	},
	methods: {
		setCurrent () {
			if (this.current) {
				if(this.range){
					const from = new Date(this.current[0])
					const to = new Date(this.current[1])
					this.time=[from,to]
				}else{
					this.time = new Date(this.current)
				}
			}
		},
		emitTime () {
			let time = ''
			if (this.range) {
				time = this.time.map((date) => {
					if (date) {
						return moment(date).format(this.format_time_store)
					}
					return null
				})
			} else {
				time = this.time
					? moment(this.time).format(this.format_time_store)
					: null
			}

			this.$emit('range', { time })
		}
	}
}
</script>
<style scoped>
label {
	display: block;
}
</style>
