
export const duplicateZones = (context, data) => {
    return axios.post('/api-admin/tasks/duplicate', data).then(res => {
        window.toast.success(window.trans_tasks.global.duplicate_task_success)
        return res
    }).catch(err => {
        window.toast.error(window.trans_tasks.global.duplicate_task_error)
        return err
    });

}

export const getBatchTasksList = (context) => {
    axios.get('/api-admin/tasks/batch/list').then((response) => {
        context.commit('setBatchTasks', response.data)
    })
}

export const getBatchTask = (context, id) => {
    axios.get('/api-admin/tasks/batch/' + id).then((response) => {
        context.commit('setCurrentBatch', response.data)
    })
}

export const deleteBatchTask = (context, id) => {
    return axios.delete('/api-admin/tasks/batch/' + id).then((response) => {
        getBatchTasksList(context)
    })
}

export const saveBatchTask = (context) => {
    return axios.post('/api-admin/tasks/batch/save', context.state.current_batch).then((response) => {
        getBatchTasksList(context)
    })
}

export const deleteSubTask = (context,{batch_id,id}) => {
    return axios.delete(`/api-admin/tasks/batch/${batch_id}/subtask/${id}`).then((response) => {
        context.commit('setCurrentBatch', response.data)
    })
}

export const getBatchSubtask = (context, id) => {
    axios.get(`/api-admin/tasks/batch/subtask/${id}`).then((response) => {
        console.log(response.data)
        context.commit('setBatchSubtask', response.data)
    })
}

export const saveBatchSubtask = (context, data) => {
    axios.post('/api-admin/tasks/batch/subtask/save', data).then((response) => {
        context.commit('setBatchSubtask', response.data)
    })
}