<template>
	<div>
		<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
			<li v-if="show_ac" class="nav-item">
				<a  :class="`nav-link ${active_tab === 'ac' ? 'active' : null}`"  id="pills-ac-tab" data-toggle="pill" href="#tabAccum" role="tab"
				   aria-controls="pills-ac" aria-selected="true">
					Acumuladores
				</a>
			</li>

			<li v-if ="show_af" class="nav-item">
				<a :class="`nav-link ${active_tab === 'af' ? 'active' : null}`" id="pills-af-tab" data-toggle="pill" href="#tabDep" role="tab" aria-controls="pills-af"
				   aria-selected="true">
					Depósitos
				</a>
			</li>
			<li v-if ="show_checkpoints" class="nav-item">
				<a :class="`nav-link ${active_tab === 'checkpoints' ? 'active' : null}`" id="pills-checkp-tab" data-toggle="pill" href="#tabCheckp" role="tab"
				   aria-controls="pills-af" aria-selected="true">
					Puntos de control
				</a>
			</li>
		</ul>
		<div class="tab-content" id="pills-tabContent">
			<div v-if='show_ac' :class="`tab-pane fade ${active_tab === 'ac' ? 'show active' : null}`" role="tabpanel" id="tabAccum">
				<reading-accumulator-list></reading-accumulator-list>
			</div>
			<div v-if="show_af" :class="`tab-pane fade ${active_tab === 'af' ? 'show active' : null}`" role="tabpanel" id="tabDep" >
				<reading-deposit-list ></reading-deposit-list>
			</div>
			<div v-if="show_checkpoints" :class="`tab-pane  fade ${active_tab === 'checkpoints' ? 'show active' : null}`" role="tabpanel" id="tabCheckp" >
				<reading-checkpoint-list ></reading-checkpoint-list>
			</div>
		</div>
	</div>
</template>
<script>
import ReadingAccumulatorList from './ReadingAccumulatorList.vue'
import ReadingDepositList from './ReadingDepositList.vue'
import ReadingCheckpointList from './ReadingCheckpointList.vue'
export default {
	name: 'ReadingsTabs',
	props: {
		task_id:{},
		show_ac: {default: true},
		show_af: {default: true},
		show_checkpoints: {default: true},
		active_tab: {default: 'ac'},
	},
	components: {
		ReadingAccumulatorList,
		ReadingDepositList,
		ReadingCheckpointList,
	},
	created() {
		this.$store.commit('readings/resetData')
		if (this.task_id) {
			this.$store.commit('readings/setCurrentTaskId', this.task_id)
			this.$store.dispatch('readings/getReadings')
		}
		console.log('current task id',this.$store.state.readings.current_task_id)
	}
}
</script>