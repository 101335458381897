<template>
<div>
	<button class='btn btn-primary' type='button' @click='toggleCheckpointModal'>
		{{ $t('select_checkpoint') }}
	</button>
	<div class=''>
		<small id='count-cp'></small>
		<small id=''> {{ $t('items_selected') }}</small>
	</div>
	<checkpoint-tree-modal v-if='installation.id' :installation_id='installation.id'
						   :checkpoints='current_checkpoints'
						   :show='showCheckpointsModal'
						   :checked-all='checkedAll'
						   :get-deposits='false'
						   :get-accumulators='false'
						   @close='hideCheckpointModal'
						   @selected='emitSelected'
	/>
</div>
</template>
<script>
import CheckpointTreeModal from './CheckpointTreeModal.vue'

export default{
	name: 'CheckpointSelectorComponent',
	components: {CheckpointTreeModal},
	props:{
		installation:{

		},
		checkedAll:{
			type: Boolean,
			default: false
		},
		current_checkpoints:{
			type: Array,
			default: () => ([])
		}
	},
	data() {
		return {
			showCheckpointsModal: false,
			selected: []
		}
	},
	methods: {
		toggleCheckpointModal() {
			this.showCheckpointsModal = !this.showCheckpointsModal
		},
		emitSelected(payload) {
			this.selected = payload
			this.$emit('selected', this.selected)
		},

		hideCheckpointModal(){
			this.showCheckpointsModal = false
		}
	}
}
</script>