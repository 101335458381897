<template>
    <div>
        <v-select
            style='min-width: 200px'
            :placeholder='$readings_locale.global.buttons.filter'
            :options='options'
            v-model='current'
            label='label'
            @input='emitSelected'
            code='code'></v-select>
    </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
    name: "ReadingFilterCheckpoint",
    props: {

    },
    created() {
		this.getData()
    },
    components: {
        vSelect,
    },
    data() {
        return {
            options: [],
            current: null,
        }
    },
    methods: {
		getData() {
			axios.get(`/api-admin/installations/checkpoints/combo`,{hideLoading: true}).then(response => {
				this.options = response
			})
		},
        emitSelected() {
			if(!this.current){
				this.$emit('setFilter',null)
			}
            this.$emit('setFilter',{'checkpoint_filter':this.current?.code})
        },
        convertOptionsSelect(data){
            let result=[];
            Object.keys(data).forEach((value,key)=>{
                result.push({'key':value,'label':data[value]})
            })
            return result;
        }
    },
}
</script>

<style scoped>

</style>
