<template>
	<modal-component
	:id='id'
	:show='show_installations_modal'
	:hideButtons='true'
	>
		<template #modalTitle>
			{{ $tasks_locale.global.select_installation }}
		</template>

		<div class='row h-100'>
			<div class='col-12 '>
				<button class='btn-installation my-2 btn btn-primary col-12'
						v-for='installation in this.getInstallations'
						@click='setInstallation(installation)'
						:key='installation.id'>{{ installation.name }}
				</button>
			</div>
		</div>

	</modal-component>
</template>
<script>
import ModalComponent from '@/ModalComponent.vue'
import {mapGetters} from 'vuex'
export default {
	name: 'InstallationsModal',
	components: {
		ModalComponent,
	},
	created() {
		this.$store.dispatch('installations/getInstallations')
	},
	computed: {
		...mapGetters('installations', ['show_installations_modal','getInstallations']),
	},
	data() {
		return {
			id: 'selectInstallationModal',
			installation: null,
			installations: [],
		}
	},
	methods: {
		cancelForm: function() {
			this.$emit('cancel')
			this.closeModal()
		},
		closeModal: function() {
			this.$store.commit('installations/hideInstallationsModal')
			$(`#${this.id}`).modal('hide')
		},
		setInstallation: function(installation) {
			this.$emit('setInstallation', installation)
			this.closeModal()
		},
	}
}
</script>