
export const setCurrentTaskId = (state, id) => {
	state.current_task_id = id;
}

export const setFilterTypeCheckpoint = (state, id) => {
	state.filter_type_checkpoint = id;
}

export const setFilterDateRange = (state, payload) => {
	if(!payload){
		payload = [];
	}else if (!payload[0]){
		payload = []
	}
	state.filter_date_range = payload;
}

export const setReadings = (state, {ac_readings, af_readings, checkpoints_readings}) => {
	state.ac_readings = ac_readings;
	state.af_readings = af_readings;
	state.checkpoints_readings = checkpoints_readings
}

export const setType = (state, payload) => {
	state.type = payload;
}
export const resetData = (state) => {
	state.type = null;
	state.current_task_id = null;
	state.filter_type_checkpoint = null;
	state.filter_date_range = [];
	state.ac_readings = [];
	state.af_readings = [];
	state.checkpoints_readings = [];
}