<template>
	<div>
		<div v-if='task_id' id='EditTask'>
			<ul class='nav nav-pills mb-3' id='pills-tab' role='tablist'>
				<li class='nav-item'>
					<a class='nav-link active' id='pills-task-tab' data-toggle='pill' href='#tabTask' role='tab'
					   aria-controls='pills-ac' aria-selected='true'>
						Tarea
					</a>
				</li>
				<li class='nav-item'>
					<a class='nav-link ' id='pills-checkp-tab' data-toggle='pill' href='#tabReadings' role='tab'
					   aria-controls='pills-af' aria-selected='true'>
						Lecturas
					</a>
				</li>
			</ul>
			<div class='tab-content' id='pills-tabContent'>
				<div class='tab-pane show fade active' role='tabpanel' id='tabTask'>
					<TasksForm
						:task_id='task_id'
						:types='types'
						:operarios='operarios'
						:states='states'
						:states_types='states_types'
						:reading_keys='reading_keys'
					/>
				</div>
				<div class='tab-pane fade' role='tabpanel' id='tabReadings'>
					<card-template >
						<ReadingsTabs :task_id="task_id" />
					</card-template>
				</div>
			</div>
		</div>
		<div v-else id='NewTask'>
			<TasksForm
				:types='types'
				:operarios='operarios'
				:installation_selected='installation_selected'
				:states='states'
				:states_types='states_types'
				:reading_keys='reading_keys'
			/>
		</div>
	</div>
</template>
<script>
import TasksForm from './TasksForm.vue'
import ReadingsTabs from '@modules/Readings/Resources/assets/components/ReadingsTabs.vue'
export default {
	name: 'TaskComponent',
	props: {
		task_id: {},
		types: {},
		operarios: {},
		installation_selected: {},
		states: {},
		states_types: {},
		reading_keys: {},
	},
	components: {
		ReadingsTabs,
		TasksForm,
	},
	created() {

	},
}
</script>