<template>
	<div>
		<div class='d-flex  align-items-center'>
			<div @click='setDay(day)'
				 :class='`week-day-bullet  justify-content-center align-items-center cursor-pointer ${selected.includes(day) ? "bg-filled" : null}`'
				 v-for='day in days'
			>
				{{ day.alias }}
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'WeekDaysComponent',
	props:{
		current:{
			type: Array,
			default: () => ([])
		}
	},
	created(){
		this.setCurrent()
	},
	watch:{
		current: function(){
			this.setCurrent()
		}
	},
	computed: {
		selectedDays() {
			const array = Array.from(this.selected).map(day => day.value)
			console.log({array})
			return array
		},
	},
	data() {
		return {
			selected: [],
			days: [
				{
					name: 'Lunes',
					alias: 'L',
					value: 1,
				},
				{
					name: 'Martes',
					alias: 'M',
					value: 2,
				},
				{
					name: 'Miércoles',
					alias: 'X',
					value: 3,
				},
				{
					name: 'Jueves',
					alias: 'J',
					value: 4,
				},
				{
					name: 'Viernes',
					alias: 'V',
					value: 5,
				},
				{
					name: 'Sábado',
					alias: 'S',
					value: 6,
				},
				{
					name: 'Domingo',
					alias: 'D',
					value: 7,
				},
			],
		}
	},
	methods: {
		setDay(day) {
			const isset = this.selected.find(d => d.value === day.value)
			if(isset){
				this.selected = this.selected.filter(d => d.value != day.value)
			}else{
				this.selected.push(day)
			}
			this.$emit('setDays',this.selected)
		},
		setCurrent(){
			if(this.current.length > 0){
				const selected = this.days.filter(d => this.current.includes(d.value))
				this.selected = selected
			}
		}
	},
}
</script>
<style lang="scss" scoped>
.week-day-bullet{
	$hover-color: #7B9FCE;
	cursor: pointer;
	border-radius: 50% !important;
	padding: calc(0.1rem) !important;
	min-width: 1.6rem !important;
	min-height: 1.6rem !important;
	width: 1.6rem !important;
	height: 1.6rem;
	margin-right: 3px;
	font-size: 0.9rem;
	text-align: center;
	background: #f9fffe;
	color: #1e1e1e;
	border: 1px solid $hover-color;
	&.bg-filled{
		color:white;
		background-color: $hover-color;
	}
}
</style>