<template>
	<div>
		<v-select
			autocomplete='off'
			:clearable='clearable'
			@input='emitSelected'
			v-model='selected'
			:multiple='multiple'
			:options='combo_items'
			label='text'
			code='id'
		></v-select>
	</div>
</template>
<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
	name: 'UsersByInstallationCombo',
	props: {
		installationId: {
			type: Number,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: true,
		},
		current:{}
	},
	components: {
		vSelect,
	},
	created() {
		this.getUsers()
	},
	watch:{
		installationId: function(){
			this.getUsers()
		},
		current: function(){
			this.setCurrent()
		}
	},
	data() {
		return {
			selected: null,
			items: [],
			combo_items: []
		}
	},
	methods: {
		getUsers() {
			if (this.installationId) {
				axios.get(`/api-admin/users/${this.installationId}`,{hideLoading:true}).then((response) => {
					this.items = response
					this.combo_items = response
					this.setCurrent()
				})
			}
		},
		emitSelected() {
			const select_values = this.selected.map(item => item.id)
			this.combo_items = this.items.filter(item => !select_values.includes(item.id))
			this.$emit('selected', this.selected)
		},
		setCurrent(){
			if(this.current){
				this.selected = this.items.filter(item => this.current.includes(item.id))
				this.combo_items = this.items.filter(item => !this.current.includes(item.id))
			}
		}
	},
}
</script>